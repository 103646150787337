@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Lato', sans-serif;
    background-color: #f6f6f6;
}
.sidebar{
    background-color: rgba(9, 84, 197);
}

.page {
    display: grid;
    grid-template-columns: 300px 1fr;
    height: 100vh;
}

.dropdown:hover .dropdown-menu {
    display: block;
}

/* TABLE */
.table th {
    text-transform: uppercase;
}
.table-bordered tbody tr {
    border-top: 1px solid #eee;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.03);
}

/* CHECKBOX TOGGLE SWITCH */
/* @apply rules for documentation, these do not work as inline style */
.toggle-checkbox:checked {
    @apply: right-0 border-green-400;
    right: 0;
    border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
    @apply: bg-green-400;
    background-color: #68D391;
}
